import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { CompanyDetails, ErrorMessage, FormContainer, Heading, InputContainer, ErrorContainer, SuccessMessage, LinkPage } from './Conctact.style'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Logo from '../../assets/images/logo.png'
import Nip from '../../assets/images/icons/contact/nip.svg'
import Envelope from '../../assets/images/icons/contact/envelope.svg'
import Phone from '../../assets/images/icons/contact/phone.svg'
import GreenCheck from '../../assets/images/icons/contact/green-dash.svg'

const FormSchema = Yup.object().shape({
  name: Yup.string('Niedozwolone znaki')
    .min(2, 'Imię to minimum 2 znaki')
    .max(20, 'Imię to maksymalnie 20 znaków')
    .required('To pole jest wymagane'),
  email: Yup.string('Niedozwolone znaki').email('Adres e-mail jest nieprawidłowy').required('To pole jest wymagane'),
  phone: Yup.string('Niedozwolone znaki')
    .required('To pole jest wymagane')
    .length(9, 'Numer telefonu to 9 znaków')
    .matches(/^[0-9]*$/u, 'Niedozwolone znaki'),
  message: Yup.string('Niedozwolone znaki')
    .min(10, 'Minimalnie 10 znaków')
    .max(5000, 'Maksymalnie 5000 znaków')
    .required('To pole jest wymagane'),
})

export default function Contact() {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [isError, setIsError] = useState(false)
  const API_PATH = '/api/index.php'
  const sendEmail = (data) => {
    fetch(API_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code !== 200) setIsError(true)
        if (res.code === 200) setIsEmailSent(true)
        console.log(res)
      })
      .catch((error) => {
        setIsError(true)
      })
  }

  return (
    <Row>
      <Col md={6}>
        <Heading>Formularz kontaktowy</Heading>
        {isError && <ErrorMessage>Niestety wystąpił błąd, spróbuj ponownie.</ErrorMessage>}
        {isEmailSent && (
          <SuccessMessage>
            Otrzymaliśmy Twoje zgłoszenie{" "}
            <img src={GreenCheck} alt="" width={20} height={20} style={{position: 'relative', top: '5px'}} />
            <span style={{display: 'block', marginTop: '12px'}}>
              Wkrótce skontaktujemy się z Tobą.
            </span>
          </SuccessMessage>
        )}
       {isEmailSent === false && isError === false && (<Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            message: '',
          }}
          validationSchema={FormSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true)
            sendEmail(data)
            console.log(data)
            setSubmitting(false)
          }}>
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormContainer>
                <InputContainer>
                  <label htmlFor="name">Imię i nazwisko</label>
                  <Field
                    id="name"
                    name="name"
                    className={errors.name && touched.name && 'error'}
                  />
                  {errors.name && touched.name && <ErrorContainer>{errors.name}</ErrorContainer>}
                </InputContainer>
                <InputContainer>
                  <label htmlFor="email">E-mail</label>
                  <Field
                    id="email"
                    name="email"
                    className={errors.email && touched.email && 'error'}
                    type="email"
                  />
                  {errors.email && touched.email && <ErrorContainer>{errors.email}</ErrorContainer>}
                </InputContainer>
                <InputContainer>
                  <label htmlFor="phone">Telefon</label>
                  <Field
                    id="phone"
                    name="phone"
                    maxlength="9"
                    className={errors.phone && touched.phone && 'error'}
                  />
                  {errors.phone && touched.phone && <ErrorContainer>{errors.phone}</ErrorContainer>}
                </InputContainer>
                <InputContainer>
                  <label htmlFor="message">Wiadomość</label>
                  <Field
                    id="message"
                    name="message"
                    className={errors.message && touched.message && 'error'}
                    as="textarea"
                    rows={7}
                  />
                  {errors.message && touched.message && <ErrorContainer>{errors.message}</ErrorContainer>}
                </InputContainer>
                <LinkPage target="_blank" href="/polityka-prywatnosci/">Zasady ochrony danych osobowych</LinkPage>
                <button type="submit" disabled={isSubmitting}>Wyślij</button>
              </FormContainer>
            </Form>
          )}
        </Formik>
)}

      </Col>
      <Col md={6} className="mt-5 mt-md-0">
        <CompanyDetails>
          <img src={Logo} className="logo" alt="" />
          <p className="company">LeadGuru Sp. z o. o.</p>
          <p>
            ul. Sycowska 44
            <br />
            51-319 Wrocław
          </p>
          <p className="contact">
            <img src={Nip} className="contact-icon" alt="" />
            NIP: 8992887765
          </p>
          <p className="contact">
            <img src={Envelope} className="contact-icon" alt="" />
            kontakt@rankingdobrychpolis.pl
          </p>
          <p className="contact">
            <img src={Phone} className="contact-icon" alt="" />
            Tel.: +48 538 234 827
          </p>
        </CompanyDetails>
      </Col>
    </Row>
  )
}
