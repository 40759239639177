import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from 'react-bootstrap'

import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Contact from '../components/Contact/Contact'

export default function ContactPage() {
  const data = useStaticQuery(query)

  const seo = data.wpPage.seo
  const Header = {
    heading: 'Kontakt',
    paragraphs: [
      'Jeżeli nie znalazłeś wszystkich informacji albo chciałbyś np. żeby w jednym z rankingów pojawiła się kolejna firma, skorzystaj z jednej z poniższych form kontaktu. Zapraszamy także jeśli jesteś zainteresowany współpracą z naszą firmą. Masz ciekawy pomysł na zrobienie czegoś fajnego wspólnie z nami? Trafiłeś pod dobry adres. Na zapytania mailowe lub z formularza odpowiemy najszybciej jak to będzie możliwe, w 90% przypadków odpowiadamy najpóźniej kolejnego dnia roboczego.',
    ]
  }

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl} />
      <Hero
        heading={Header.heading}
        paragraphs={Header.paragraphs}
        img={
          <StaticImage
            src="../assets/images/about/about.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container className="mt-4">
        <Contact />
      </Container>
      <Container className="my-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5011.4938359444!2d17.018780000000003!3d51.094678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3f524a702549675!2sLeadGuru%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1629895424334!5m2!1spl!2spl"
          width="100%"
          height="450"
          style={{border:0}}
          allowfullscreen=""
          title="siedziba LeadGuru"
          loading="lazy"></iframe>
      </Container>
    </Layout>
  )
}

const query = graphql`
  {
    wpPage(slug: { eq: "kontakt" }) {
      seo {
        metaDesc
        title
        focuskw
        opengraphUrl
      }
    }
  }
`
