import styled, { keyframes } from 'styled-components'
import { color, fontFamily } from '../../assets/styles/variables'

export const Paragraph = styled.p`
  font-size: 16px;
`

export const Heading = styled.h2`
  font-size: 30px;
  margin-top: 0;
  color: ${color.textDark};
  font-weight: 700;
`
export const ErrorContainer = styled.span`
  color: ${color.error};
  font-size: 12px;
`
export const InputContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  input,
  textarea {
    border: 2px solid rgba(27, 55, 96, 0.1);
    border-radius: 3px;
    min-height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    font-family: ${fontFamily.fontSecondary};
    color: ${color.primary};
  }
  label {
    position: absolute;
    color: ${color.primary};
    z-index: 2;
    top: -14px;
    left: 20px;
    height: 20px;
    margin-bottom: 0;
    pointer-events: none;
    cursor: text;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: all 0.1s ease-in-out;
    padding: 0px 10px 0px 5px;
    display: inline-block;
    &::after {
      content: ' ';
      background-color: ${color.background};
      width: 100%;
      height: 5px;
      position: absolute;
      left: 0;
      top: 9px;
      z-index: -1;
    }
  }
  .error {
    border: 2px solid ${color.error};
  }
  button {
    color: #ffffff;
    background-color: #fb5214;
    border-radius: 7px;
    margin-top: 20px;
    border: 0;
    font-size: 18px;
    padding: 20px 40px 20px 40px;
    font-weight: 600;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-family: 'Hind', sans-serif;
    text-align: center;
    max-height: 68px;
    -webkit-text-decoration: none;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    line-height: 1.4;
  }
`

export const CompanyDetails = styled.div`
  color: ${color.textDark};
  img.logo {
    max-width: 250px;
    margin-bottom: 0px;
  }
  p.company {
    font-weight: 600;
    font-size: 18px;
  }
  p {
    font-weight: 400;
    font-size: 17px;
  }
  p.contact {
    display: flex;
    align-items: center;
  }
  .contact-icon {
    width: 20px;
    margin-right: 10px;
  }
`

const OpacityAnimation = keyframes`
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
`

export const ErrorMessage = styled.p`
  color: ${color.error};
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  animation: 1s ease 0s 1 ${OpacityAnimation};
`

export const SuccessMessage = styled.p`
  font-size: 14px;
  animation: 1s ease 0s 1 ${OpacityAnimation};
  color: #00b900;
`

export const LinkPage = styled.a`
  color: ${color.textDark};
  font-weight: 500;
  text-decoration: none;
`